import React from "react"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { Paper } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import { Link } from "gatsby"
import { EmailOutlined } from "@material-ui/icons"

const CtaContact = props => {
  return (
    <Container maxWidth="md">
      <Box marginTop={6} marginBottom={6} padding={3} align="center" component={Paper}>
        <Typography variant="h5" tag="h2" color="primary">
          Schedule your free consultation now
        </Typography>
        <Box marginTop={2}>
          <Button variant="contained" color="primary" size="large" component={Link} to="/contact"
                  startIcon={<EmailOutlined/>}>
            Contact Me Now
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

CtaContact.propTypes = {}

CtaContact.defaultProps = {}

export default CtaContact
