import React from "react"
import bgImage from "../assets/mccalla-coaching-header-background.webp"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import PageHeader from "../components/PageHeader"
import { Container, createStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CtaContact from "../components/CTAContact"

const useStyles = makeStyles((theme) =>
  createStyles({
    headlineTop: {
      fontSize: 38,
      fontWeight: 400,
    },
  })
);

const IndexPage = () => {
  const styles = useStyles();
  return (
    <>
      <SEO title="McCalla"/>
      <PageHeader backgroundImage={bgImage} backgroundImageHeight={60} backgroundTextTopPosition={"50%"}>
        <Typography variant="h1">
          Faster, Farther, Smarter
          {/*<span className={styles.headlineTop}>"Redefine your peak performance.</span><br/> Realize your full potential."*/}
        </Typography>
        {/*<Typography variant="h3">of Human weirdness"</Typography>*/}
      </PageHeader>
      <Container>

        {/*  <Typography variant="h2">*/}
        {/*    Blow away your limits*/}
        {/*  </Typography>*/}

        <Box marginTop={4} marginBottom={4}>
          <Typography variant="h6" paragraph>
            You are much more than your training statistics, and so are your race results.
            To get you to your very best, Michael will help you improve all aspects of your cycling with straightforward
            workouts and exercises designed to meet your needs and match your lifestyle. <br/><br/>
            He has 12 years of experience coaching cyclists of various disciplines as well as multi-sport athletes from
            the ages of 14-70.
            Whether your goals are to stay at the front of your group ride, win a KOM, crush an ultra-endurance race or
            compete at the World Championships, Michael will guide you on the path to becoming a complete cyclist.
          </Typography>
          <CtaContact />
        </Box>
      </Container>
    </>
  );
}

export default IndexPage
